// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/Calendar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_Calendar(
  $location_id: uuid!
  $date: date!
  $appt_type_filter: vaxiom_appointment_types_bool_exp!
  $should_filter: Boolean!
) {
  vaxiom_chairs(
    where: { resource: { organization: { _id: { _eq: $location_id } } } }
  ) {
    id
    _id
  }
  calendar_chairs(
    where: { location_id: { _eq: $location_id }, date: { _eq: $date } }
  ) {
    # for apps/admin/src/Data/ApiCacheExchangeKeys.js
    chair_id
    date
    day_schedule_id
    location_id
    chair {
      id
      _id
      pos
      full_name
    }
    provider {
      id
      _id
      title
      first_name
      last_name
    }
    assistant {
      id
      _id
      title
      first_name
      last_name
    }
    office_hours(order_by: { start_min: asc }) {
      day_schedule_id
      start_min
      end_min
    }
    appointment_slots(limit: 1) {
      appt_type_id
    }
    selected_appointment_slots: appointment_slots(
      where: { appointment_type: $appt_type_filter }
    ) @include(if: $should_filter) {
      appt_type_id
      appointment_type {
        id
        appointment_templates(
          where: {
            deleted: { _eq: false }
            organization: { _id: { _eq: $location_id } }
          }
        ) {
          id
          full_name_computed
          color_id_computed
          duration
        }
      }
      day_schedule_id
      start_min
    }
  }
}

`