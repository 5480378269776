// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/SignContract/Content/Content/Content/PreviewContract/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_SignContract_Content_Content_Content_PreviewContract(
  $document_template_id: uuid!
  $data: jsonb!
  $patient_id: uuid!
  $treatment_plan_id: uuid!
) {
  vaxiom_get_preview_contract(
    document_template_id: $document_template_id
    data: $data
    patient_id: $patient_id
    treatment_plan_id: $treatment_plan_id
  ) {
    id
    ok
    url
  }
}

`