import React, { useEffect, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import useIsHovered from 'Simple/hooks/useIsHovered.js'
import { useFloating, offset, flip, autoUpdate } from '@floating-ui/react-dom'
import View from './view.js'

export default function Logic(props) {
  let triggerRef = useRef(null)
  let timerRef = useRef(null)
  let hoverTriggerDelay = props.hoverTriggerDelay || 0
  let hoverLeaveDelay = props.hoverLeaveDelay || 200

  let [isLeftClickOpen, setIsLeftClickOpen] = useState(false)
  let [isRightClickOpen, setIsRightClickOpen] = useState(false)
  let [isHoverOpen, setIsHoverOpen] = useState(false)

  // Handle hover functionality
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })
  useEffect(() => {
    if (props.showOnHover) {
      setIsHoverOpen(
        props.disabled !== true &&
          hovered &&
          !isLeftClickOpen &&
          !isRightClickOpen
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hovered,
    isLeftClickOpen,
    isRightClickOpen,
    props.showOnHover,
    props.disabled,
  ])

  // Popover floating content
  let { refs, x, y, elements, update } = useFloating({
    open: isLeftClickOpen || isRightClickOpen || isHoverOpen,
    placement: props.placement || 'bottom-start',
    strategy: 'fixed',
    middleware: [
      flip(),
      offset({
        crossAxis: props.offsetCrossAxis ?? 0,
        mainAxis: props.offsetMainAxis ?? 12,
      }),
    ],
    // e.g. update position when popover parent is scrollable (e.g. Appointment Overlay)
    whileElementsMounted: autoUpdate,
  })

  // Handle click outside for content opened with left or right click
  useOnClickOutside(refs.floating, event => {
    if (
      event.target === triggerRef.current ||
      triggerRef.current?.contains(event.target)
    ) {
      return
    }

    // When we have nested popovers, this can be used on root Popover to decide if to close root Popover when clicking outside of child Popover
    if (
      typeof props.ignoreClickOutsideIf === 'function' &&
      props.ignoreClickOutsideIf(event)
    ) {
      return
    }

    setIsLeftClickOpen(false)
    setIsRightClickOpen(false)
  })

  return (
    <View
      {...props}
      isLeftClickOpen={isLeftClickOpen}
      isRightClickOpen={isRightClickOpen}
      isHoverOpen={isHoverOpen}
      hovered={hovered}
      onLeftClick={onLeftClick}
      onRightClick={onRightClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      onBlur={onBlur}
      closePopover={closePopover}
      parentRef={refs.setReference}
      triggerRef={triggerRef}
      contentRef={refs.setFloating}
      left={x}
      top={y}
      width={props.width ?? elements.reference?.getBoundingClientRect()?.width}
    />
  )

  function onLeftClick(event) {
    /**
     * It may happen that after hovering over the day, the user clicks on
     * it, so in that case, we should ignore the hover action and treat it
     * as a left click action only
     */
    if (timerRef.current) clearTimeout(timerRef.current)

    /**
     * Using `!props.showOnClick` instead of `props.showOnClick === false`
     * as it would filter out the condition of `false` and `undefined`
     * both. However, we want `showOnClick = undefined` to be treated as
     * `true` to be backward compatible with the previous implementation
     * of the component
     */
    if (props.disabled || props.showOnClick === false) return

    event.preventDefault()
    setIsLeftClickOpen(true)
    setIsRightClickOpen(false)
    setIsHoverOpen(false)
    update()
  }

  function onRightClick(event) {
    /**
     * It may happen that after hovering over the day, the user right clicks on
     * it, so in that case, we should ignore the hover action and treat it
     * as a right click action only
     */
    if (timerRef.current) clearTimeout(timerRef.current)

    if (props.disabled || !props.showOnRightClick) return

    event.preventDefault()
    setIsLeftClickOpen(false)
    setIsRightClickOpen(true)
    setIsHoverOpen(false)
    update()
  }

  function closePopover() {
    setIsLeftClickOpen(false)
    setIsRightClickOpen(false)
    setIsHoverOpen(false)
  }

  function onMouseEnter() {
    if (!props.showOnHover) return
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      hoverBind.onMouseEnter()
    }, hoverTriggerDelay)
  }

  function onMouseLeave() {
    if (!props.showOnHover) return
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      hoverBind.onMouseLeave()
    }, hoverLeaveDelay)
  }

  function onKeyUp(event) {
    if (event.key === 'Escape') {
      closePopover()
    }
  }

  function onFocus(event) {
    setIsLeftClickOpen(true)
    setIsRightClickOpen(false)
    setIsHoverOpen(false)
    update()

    if (props.onFocus) {
      props.onFocus(event)
    }
  }

  function onBlur(event) {
    // add a timeout if the user selected an option
    setTimeout(closePopover, 300)

    if (props.onBlur) {
      props.onBlur(event)
    }
  }
}
