import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

let STEPS = /** @type {const} */ ({
  TREATMENT_PLAN_TEMPLATES: 'treatment_plan_templates',
  TREATMENT_PLANS: 'treatment_plans',
  PAYMENT_PLANS: 'payment_plans',
  SIGN_CONTRACT: 'sign_contract',
})

let NEXT_STEP_ACTION = /** @type {const} */ ({
  [STEPS.TREATMENT_PLAN_TEMPLATES]: STEPS.TREATMENT_PLANS,
  [STEPS.TREATMENT_PLANS]: STEPS.PAYMENT_PLANS,
  [STEPS.PAYMENT_PLANS]: STEPS.SIGN_CONTRACT,
  [STEPS.SIGN_CONTRACT]: STEPS.PAYMENT_PLANS,
})

let BACK_STEP_ACTION = /** @type {const} */ ({
  [STEPS.TREATMENT_PLANS]: STEPS.TREATMENT_PLAN_TEMPLATES,
  [STEPS.PAYMENT_PLANS]: STEPS.TREATMENT_PLANS,
  [STEPS.SIGN_CONTRACT]: STEPS.PAYMENT_PLANS,
})

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'continue': {
        change(next => {
          if (next.current_step === STEPS.SIGN_CONTRACT) {
            next.completed = true
          }
          next.current_step =
            NEXT_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'back': {
        change(next => {
          if (next.current_step === STEPS.SIGN_CONTRACT) {
            next.completed = false
          }

          next.current_step =
            BACK_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'accept_treatment': {
        break
      }
      case 'cancel': {
        // reset tx plans selection
        change(next => {
          next.completed = false
          next.current_step = STEPS.TREATMENT_PLAN_TEMPLATES
        })
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      case 'done': {
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      default: {
        break
      }
    }
  }
}
