import { useDataValue } from 'Simple/Data.js'
import { dateShortOut } from 'Data/format.js'

export default function useDataConfiguration(props) {
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let selected_appointment_templates = useDataValue({
    context: 'settings',
    path: 'selected_appointment_templates',
    viewPath: props.viewPath,
  })
  let shortDate = dateShortOut(date)

  return {
    variables: {
      date: shortDate,
      location_id,
      appt_type_filter:
        selected_appointment_templates.length > 0
          ? {
              appointment_templates: {
                _id: { _in: selected_appointment_templates },
              },
            }
          : {},
      should_filter: selected_appointment_templates.length > 0,
    },
    pause: !date || !location_id || !selected_appointment_templates,
  }
}
