import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'editor',
  })

  return function onClick() {
    submit({
      type: 'cancel',
    })
  }
}
