// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/mutation-cancel-request.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan(
  $treatment_plan_id: uuid!
  $person_id: uuid!
) {
  treatments_request_cancel(
    tx_plan_id: $treatment_plan_id
    person_id: $person_id
  ) {
    ok
  }
}

`