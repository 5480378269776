import { useDataValue } from 'Simple/Data'
import useFetch from 'Data/useFetch'
import { useMemo } from 'react'
import { useGetSignedUrl } from 'Data/s3'

export default function useDataTransform(props, data) {
  let url = useDataValue({
    context: 'document_template',
    path: 'url',
    viewPath: props.viewPath,
  })
  let preview_url = useGetSignedUrl({
    once: true,
    url,
    viewPath: props.viewPath,
  })

  let {
    data: content,
    fetching,
    error,
  } = useFetch(preview_url, { cache: 'reload' })

  return useMemo(
    () => ({
      value: content,
      fetching,
      error,
    }),
    [content, fetching, error]
  )
}
