import { useMemo } from 'react'
import { calendarSlotMinuteToFormattedTime } from 'Data/format.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let updated_at = Date.now()

    return data.map(slot => {
      let id = `${slot.chair_id}_${slot.date}_${slot.start_min}_${slot.end_min}`

      return {
        ...slot,
        id,
        variant: 'templated',
        chair_name: slot.chair.full_name,
        start_time_formatted: calendarSlotMinuteToFormattedTime(slot.start_min),
        end_time_formatted: calendarSlotMinuteToFormattedTime(slot.end_min),
        // this is basically a hack, but in some cases we are forcing refetch and expecting
        // `useDataOnChange` to be fired, no matter if data did not change
        // this forces it to trigger even though the cache urql data did not change
        // mainly used for preselecting slot
        _updated_at: updated_at,
      }
    })
  }, [data])
}
