import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      appointment_booking: data.vaxiom_appointment_bookings[0],
      patient: data.vaxiom_patients[0],
      payment_overdue: data.financial_patient_due_amount,
    }
  }, [data])
}
