import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useMemo } from 'react'
import View from './view.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications'
import TysiaCheckbox from 'DesignSystem/TysiaCheckbox/index.js'
import TysiaCheckboxInput from 'DesignSystem/TysiaCheckbox/TysiaCheckboxInput/index.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'

export default function Logic(props) {
  let feature_flags = useDataValue({
    context: 'feature_flags',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'feature_flags',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let value = useMemo(() => {
    return {
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Level',
          accessor: 'level',
        },
        {
          Header: 'Beta profile',
          columns: [
            {
              Header: 'Enabled',
              accessor: 'new_profile_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
            {
              Header: 'Default',
              accessor: 'new_profile_default_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          Header: 'New reporting',
          columns: [
            {
              Header: 'Default',
              accessor: 'new_reporting_default_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          Header: 'Apps',
          columns: [
            {
              Header: 'Enabled',
              accessor: 'new_apps_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          Header: 'Marketing Analytics',
          columns: [
            {
              Header: 'Enabled',
              accessor: 'new_marketing-analytics_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          Header: 'Beta calendar',
          columns: [
            {
              Header: 'Enabled',
              accessor: 'new_calendar_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
            {
              Header: 'Default',
              accessor: 'new_calendar_default_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          Header: 'Beta Treatment Plan',
          columns: [
            {
              Header: 'Enabled',
              accessor: 'new_tx_planning_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
            {
              Header: 'Default',
              accessor: 'new_tx_planning_default_enabled',
              Cell: context => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(context)}
                  onChange={value => onChange(context, value)}
                  value={context.value}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
      ],
      data: feature_flags || [],
    }

    async function onChange(context, value) {
      change(next => {
        let item = next.find(item => item.id === context.row.original.id)
        item[context.column.id] = value
      })

      let res = await executeMutation({
        organization_id: context.row.original.id,
        message_key: context.column.id.replaceAll('_', '.'),
        enabled: value,
      })

      let featureFlagName = context.column.parent
        ? `${
            context.column.parent.Header
          } ${context.column.Header.toLowerCase()}`
        : context.column.Header

      if (res.error) {
        return notify(
          notifyError(
            `Can't toggle "${featureFlagName}" ${value ? 'on' : 'off'} at ${
              context.row.original.name
            }. Contact support if the problem persists.`
          )
        )
      }

      notify(
        notifySuccess(
          `Toggled "${featureFlagName}" ${value ? 'on' : 'off'} at ${
            context.row.original.name
          }`
        )
      )
    }

    function isDisabled(context) {
      if (context.row.original.level === 'parent') return false

      let parent = context.rows.find(item => item.original.level === 'parent')
      return parent?.values[context.column.id]
    }
  }, [feature_flags]) // eslint-disable-line
  // ignore executeMutation, change, notify and props.viewPath

  return <View value={value} viewPath={props.viewPath} />
}
