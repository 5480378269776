// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/mutation-send-request.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan(
  $treatment_plan_id: uuid!
  $patient_id: uuid!
  $person_id: uuid!
  $current_location_id: numeric!
  $payment_plan: jsonb!
) {
  treatments_save_payment_plan(
    args: {
      current_location_id: $current_location_id
      payment_plan: $payment_plan
    }
  ) {
    id
  }
  treatments_request_create(
    tx_plan_id: $treatment_plan_id
    patient_id: $patient_id
    person_id: $person_id
  ) {
    id
  }
}

`