export function getLocationDetails(selected) {
  return {
    id: selected.location_id,
    vaxiom_id: selected.vaxiom_location_id,
    time_zone_id: selected.time_zone_id,
  }
}

export function getSchedulingConfig(duration, conflict_permission) {
  return {
    duration,
    conflict_permission,
  }
}
