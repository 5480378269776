import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let document_template_id = useDataValue({
    viewPath: props.viewPath,
    context: 'contracts',
    path: 'document_template_id',
  })

  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })

  return {
    variables: {
      document_template_id,
      patient_id,
      treatment_plan_id: '45bfdac5-9b80-47ac-a9a2-f151b5e67a63',
      // Selected data
      data: {
        treatment_fee: 10000,
        discounts: [
          {
            id: '75502098-91bf-4c5e-ae4f-c0f203d0c61a',
            type_id: 1,
            type: 'amount',
            amount: 100,
            percentage: 1,
            is_included_in_claim: false,
            is_applied_to_payor: false,
            payor_id: null,
          },
        ],
        charges: [
          {
            id: '7b40efa0-4616-4047-ab36-e687c92b89dd',
            type_id: 1,
            amount: 40,
            is_included_in_claim: false,
            is_applied_to_payor: false,
            payor_id: null,
          },
        ],
        insurances: [
          {
            id: '0c4abd30-31bb-4f28-b8d1-30eb3ef37d2b',
          },
        ],
        primary_payor: {
          id: 'c13b87bd-25b8-49eb-8515-723e4e170bd8',
          share: {
            type: 'percentage',
            amount: 9940,
            percentage: 100,
          },
          downpayment: 9940,
          installment_amount: 0,
          payment_plan_length: 0,
          installment_interval: 'Monthly',
          first_due_date: '2024-10-01',
          first_installment_date: 1,
          second_installment_date: 1,
        },
        secondary_payor: null,
      },
    },
    pause: !document_template_id || !patient_id,
  }
}
