// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/AppointmentData/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_AppointmentData_Content($appointment_id: uuid!, $location_id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $appointment_id } }) {
    id
    _id
    type {
      id
      _id
      is_virtual
      appointment_templates(
        where: {
          organization: { _id: { _eq: $location_id } }
          deleted: { _eq: false }
        }
      ) {
        id
        duration
      }
    }
    patient {
      id
      _id
    }
    tx {
      id
      _id
    }
    booking {
      id
      _id
      local_start_time
      local_start_date
      chair {
        id
        _id
      }
    }
  }
}

`