import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let url = useDataValue({
    viewPath: props.viewPath,
    context: 'preview_contract',
    path: 'url',
  })

  return {
    variables: { url },
    pause: !url,
  }
}
