// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/Form/Content/AppointmentSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_Form_Content_AppointmentSelect(
  $patient_id: uuid!
  $treatment_id: uuid!
  $location_id: uuid!
) {
  unscheduled: appointments_upcoming_unscheduled_appointments_by_patient(
    args: { patient_id: $patient_id }
    where: {
      tx: { _id: { _eq: $treatment_id } }
      type: { is_virtual: { _eq: false } } # will be removed later
    }
  ) {
    id
    _id
    tx {
      id
      _id
      tx_card {
        id
        _id
        name
      }
    }
    type {
      id
      _id
      display_name
      display_color_id
      is_virtual
      appointment_templates(
        where: {
          organization: { _id: { _eq: $location_id } }
          deleted: { _eq: false }
        }
      ) {
        id
        duration
      }
    }
  }
  scheduled: vaxiom_appointments(
    where: {
      patient: { _id: { _eq: $patient_id } }
      booking: { state: { _eq: "SCHEDULED" } }
      tx: { _id: { _eq: $treatment_id } }
      type: { is_virtual: { _eq: false } } # will be removed later
    }
  ) {
    id
    _id
    tx {
      id
      _id
      tx_card {
        id
        _id
        name
      }
    }
    type {
      id
      _id
      display_name
      display_color_id
      is_virtual
      appointment_templates(
        where: {
          organization: { _id: { _eq: $location_id } }
          deleted: { _eq: false }
        }
      ) {
        id
        duration
      }
    }
    booking {
      id
      _id
      local_start_date
      local_start_time
      duration
      chair {
        id
        _id
      }
    }
  }
}

`