// this is an autogenerated file from DesignSystem/TimeGrid/FullCalendarTimegrid/Appointment/InfoDialog/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimeGrid_FullCalendarTimegrid_Appointment_InfoDialog_Content($patient_id: uuid!, $appointment_booking_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    where: { due_now: { _gt: 0 } }
  ) {
    id: payment_account_id
    due_now
    payment_account {
      id
      account_type
      person_payment_account {
        id
        _id
        payer_person {
          id
          _id
          first_name
          greeting
          last_name
        }
      }
      insurance_payment_accounts {
        id
        insurance_company {
          id
          carrier_name
        }
      }
    }
  }

  vaxiom_appointment_bookings(
    where: { _id: { _eq: $appointment_booking_id } }
  ) {
    id
    local_start_date
    local_start_time
    duration
    state
    confirmation_status
    provider {
      id
      employment_contract {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
    assistant {
      id
      employment_contract {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
    appointment {
      id
      _id
      has_notes
      notes(where: { note: { _neq: "" } }) {
        id
        note
        author_user {
          id
          person {
            id
            first_name
            last_name
          }
        }
      }
      type {
        id
        display_name
        display_color_id
      }
      tx {
        id
        tx_status_name {
          id
          name
        }
        tx_card {
          id
          name
        }
      }
    }
  }

  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    professional_relationships(
      where: {
        dtype: { _eq: "otherProfessionalRelationship" }
        past: { _eq: false }
      }
    ) {
      id
      other {
        id
        person {
          id
          first_name
          last_name
          profile_pic_url
        }
      }
    }
    person {
      id
      _id
      first_name
      last_name
      birth_date
      profile_pic_url
      to_person_relationships(where: { role: { _eq: "RESPONSIBLE" } }) {
        id
        type
        role
        from_person_record {
          id
          first_name
          middle_name
          last_name
          profile_pic_url
          emails: contact_method_associations(
            where: { contact_method: { dtype: { _eq: "email" } } }
            limit: 1
          ) {
            id
            email {
              id
              address
            }
          }
          phones: contact_method_associations(
            where: { contact_method: { dtype: { _eq: "phone" } } }
            limit: 1
          ) {
            id
            phone {
              id
              number
            }
          }
          patient {
            id
            appointments(
              where: {
                tx: {
                  deleted: { _eq: false }
                  tx_card: { deleted: { _eq: false } }
                }
              }
              limit: 1
              order_by: { sys_created: desc }
            ) {
              id
              tx {
                id
                tx_status_name {
                  id
                  name
                }
                tx_card {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}

`