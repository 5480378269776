import { useMutation, useClient } from 'Data/Api.js'
import mutationMarkAsConfirmed from './mutation-mark-as-confirmed.graphql.js'
import mutationCancelAppointment from './mutation-cancel-appointment.graphql.js'
import mutationCheckInPatient from './mutation-check-in-patient.graphql.js'
import mutationMarkAsNoShow from './mutation-mark-as-no-show.graphql.js'
import mutationRollabackAppointmentState from './mutation-rollback-appointment-state.graphql.js'
import mutationSeat from './mutation-seat.graphql.js'
import mutationUnseat from './mutation-unseat.graphql.js'
import mutationCheckout from './mutation-check-out.graphql.js'
import mutationDeleteAppointment from './mutation-delete-appointment.graphql.js'
import mutationSendLink from './mutation-send-link.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let markAsConfirmed = useMarkAsConfirmed(props, data)
  let cancelAppointment = useCancelAppointment(props, data)
  let checkInPatient = useCheckInPatient(props, data)
  let markAsNoShow = useMarkAsNoShow(props, data)
  let rollbackAppointmentState = useRollbackAppointmentState(props, data)
  let seat = useSeat(props, data)
  let unseat = useUnseat(props, data)
  let checkout = useCheckOut(props, data)
  let deleteAppointment = useDeleteAppointment(props, data)
  let scheduleVia = useScheduleVia(props, data)

  let client = useClient()

  return async function onSubmit({ args, ...params }) {
    switch (args.type) {
      case 'markAsConfirmed': {
        await markAsConfirmed({ args, ...params })
        break
      }
      case 'cancelAppointment': {
        await cancelAppointment({ args, ...params })
        break
      }
      case 'checkIn': {
        await checkInPatient({ args, ...params })
        break
      }
      case 'markAsNoShow': {
        await markAsNoShow({ args, ...params })
        break
      }
      case 'cancelCheckIn':
      case 'cancelCheckOut':
      case 'cancelSeating':
      case 'cancelUnseating': {
        await rollbackAppointmentState({ args, ...params })
        break
      }
      case 'seat': {
        await seat({ args, ...params })
        break
      }
      case 'unseat': {
        await unseat({ args, ...params })
        break
      }
      case 'checkOut': {
        await checkout({ args, ...params })
        break
      }
      case 'deleteAppointment': {
        await deleteAppointment({ args, ...params })
        break
      }
      case 'scheduleVia': {
        await scheduleVia({ args, ...params })
        break
      }
      default: {
      }
    }

    // Once the action is executed, call the `onClickComplete` function if it exists
    if (typeof props.onClickComplete === 'function') props.onClickComplete()
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useMarkAsConfirmed(props, data) {
  let [, executeMutation] = useMutation(mutationMarkAsConfirmed)
  let [, notify] = useNotifications()

  return async function markAsConfirmed({ value }) {
    let mutationResponse = await executeMutation({
      appointment_booking_id: value.appointment_booking.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Could not confirm appointment'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useCancelAppointment(props, data) {
  let [, executeMutation] = useMutation(mutationCancelAppointment)
  let [, notify] = useNotifications()

  return async function cancelAppointment({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem cancelling the appointment. Please try again.`
        )
      )
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useCheckInPatient(props, data) {
  let [, executeMutation] = useMutation(mutationCheckInPatient)
  let [, notify] = useNotifications()

  return async function checkInPatient({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem checking the patient in. Please try again.`
        )
      )
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useMarkAsNoShow(props, data) {
  let [, executeMutation] = useMutation(mutationMarkAsNoShow)
  let [, notify] = useNotifications()

  return async function markAsNoShow({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem marking the appointment booking as NO_SHOW. Please try again.`
        )
      )
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useRollbackAppointmentState(props, data) {
  let [, executeMutation] = useMutation(mutationRollabackAppointmentState)
  let [, notify] = useNotifications()

  return async function rollbackAppointmentState({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem cancelling the appointment booking state. Please try again.`
        )
      )
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useUnseat(props, data) {
  let [, executeMutation] = useMutation(mutationUnseat)
  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function unseat({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
      skip_required_fields: args?.skip_required_fields || false,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Cannot unseat selected appointment. Please try again.')
      )
      return
    }

    if (
      mutationResponse.data.vaxiom_appointment_booking_unseat.status ===
      'missing_required_treatment_fields'
    ) {
      setFlowTo(
        normalizePath(
          props.viewPath,
          'Unseat/Content/MissingRequiredTreatmentFieldsDialog/Content'
        )
      )
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useCheckOut(props, data) {
  let [, executeMutation] = useMutation(mutationCheckout)
  let [, notify] = useNotifications()

  return async function checkout({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Cannot checkout patient. Please try again.'))
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useSeat(props, data) {
  let [, executeMutation] = useMutation(mutationSeat)
  let [, notify] = useNotifications()

  return async function seat({ value, args }) {
    if (!args.chair_id || !args.provider_id || !args.assistant_id) {
      return true
    }

    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
      provider_id: args.provider_id,
      assistant_id: args.assistant_id,
      chair_id: args.chair_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem seating the patient. Please try again.`
        )
      )
      return
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDeleteAppointment(props, data) {
  let [, executeMutation] = useMutation(mutationDeleteAppointment)
  let [, notify] = useNotifications()

  return async function deleteAppointment({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment.id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem deleting the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The appointment was deleted successfully.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useScheduleVia(props, data) {
  let [, executeMutation] = useMutation(mutationSendLink)
  let [, notify] = useNotifications()

  return async function scheduleVia({ value, args }) {
    let { selected_contact_method, custom_instructions } = args
    if (!selected_contact_method) {
      notify(notifyError(`Could not find the selected contact method`))

      return
    }

    let mutationResponse = await executeMutation({
      appointment_id: value.appointment.id,
      phone:
        selected_contact_method.dtype === 'phone'
          ? selected_contact_method.phone.number
          : null,
      email:
        selected_contact_method.dtype === 'email'
          ? selected_contact_method.email.address
          : null,
      custom_instructions: custom_instructions,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem sending the scheduling link. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The scheduling link was sent successfully.`))
  }
}
