// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/PatientSelect/Content/Content/Existing/PatientSelect/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_PatientSelect_Content_Content_Existing_PatientSelect_Content($primary_location_id: uuid!, $search_term: String!) {
  vaxiom_patients(
    where: {
      primary_location: { _id: { _eq: $primary_location_id } }
      person: { search_name: { _ilike: $search_term } }
    }
    order_by: { person: { last_name: asc } }
    limit: 25
  ) {
    id
    _id
    human_readable_id
    person {
      id
      first_name
      last_name
      gender
      birth_date
    }
    primary_location {
      id
      name
    }
  }
}

`