export function getView(content) {
  if (content.error) {
    return 'Error'
  } else if (content.fetching) {
    return 'Loading'
  } else if (!content.value) {
    return 'Empty'
  } else {
    return 'Content'
  }
}
