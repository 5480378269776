import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let contracts = useDataValue({
    context: 'contracts',
    viewPath: props.viewPath,
  })
  useEffect(() => {
    if (!contracts?.length) return

    change(next => {
      if (!next.document_template_id) {
        next.document_template_id = contracts[0].id
      }
    })
  }, [])

  return <View {...props} />
}
