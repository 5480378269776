import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let patient = useDataValue({
    context: 'event',
    path: 'appointment.patient',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        id: patient._id,
        type: 'Patient',
        name: patientName(patient.person),
        tab: 'ledger',
        viewPath: '/App/Account/Content/Patients',
        patient_id: patient._id,
      },
    })
  }
}
