import useFetch from 'Data/useFetch.js'
import { DataProvider, useSetFlowToBasedOnData } from 'Simple/Data.js'
import View from './view.js'
import useDataConfiguration from './useDataConfiguration.js'

export default function Logic(props) {
  let configuration = useDataConfiguration(props)
  let { data, error } = useFetch(configuration.variables.url)

  useSetFlowToBasedOnData({
    data,
    fetching: !data,
    error,
    pause: configuration.pause,
    viewPath: props.viewPath,
  })

  return (
    <DataProvider context="content" value={data} viewPath={props.viewPath}>
      <View {...props} />
    </DataProvider>
  )
}
