import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  let appointment_templates = useDataValue({
    context: 'appointment_templates',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected_appointment_templates) {
    submit({
      key: 'selected_appointment_templates',
      value: appointment_templates
        .filter(appointment_template =>
          selected_appointment_templates.includes(appointment_template.id)
        )
        .map(appointment_template => appointment_template._id),
    })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}

export function getSelected(
  appointment_templates,
  selected_appointment_templates
) {
  return appointment_templates
    .filter(appointment_template =>
      selected_appointment_templates.includes(appointment_template._id)
    )
    .map(appointment_template => appointment_template.id)
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => valueRegex.test(item.full_name))
  }
}
