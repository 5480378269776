import React from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { isEditable } from '../helpers.js'

import View from './view.js'

export default function Logic(props) {
  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_note',
  })
  let appointment = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
    path: 'appointment',
  })
  let onClick = useOnClick(props)

  // passing onClick through logic.js as the morpher doesn't support conditionals for event listeners
  return (
    <View {...props} onClick={isEditable(note, appointment) ? onClick : null} />
  )
}

function useOnClick(props) {
  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_note',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'edited_note',
  })

  return function onClick() {
    change({
      id: note.id,
      note: note.note,
      selected_user_id: note.author,
      state: 'update',
    })
  }
}
