// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Request/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Request_Content($id: uuid!) {
  treatments_requests_by_pk(id: $id) {
    id
    tx_plan_id
    person_id
    person {
      id
      first_name
      last_name
    }
    contact_method_association {
      id
      contact_method {
        id
        dtype
        phone {
          number
        }
        email {
          address
        }
      }
    }
  }
}

`