// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Send/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Send_Content(
  $treatment_plan_id: numeric!
  $contact_method_association_id: numeric!
) {
  treatments_request_send(
    treatment_plan_id: $treatment_plan_id
    contact_method_association_id: $contact_method_association_id
  ) {
    ok
  }
}

`