// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/SignContract/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_SignContract_Content($organization_id: uuid!) {
  vaxiom_document_templates(
    where: {
      organization: { _id: { _eq: $organization_id } }
      is_contract: { _eq: true }
    }
    order_by: { name: asc_nulls_last }
  ) {
    id: _id
    text: name
  }
}

`