import { useMemo } from 'react'
import { employeeName } from 'Data/format'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    // Resources for calendar: all the chairs defined for the location
    // are retrieved in case there are some without configuration
    // but still with appointment bookings at the specified date
    // The decision to display or not the chair is made in children
    // data context -calendar- based on both chair configuration from here and
    // appointment bookings coming from -events- data context

    let chairs = data?.vaxiom_chairs ?? []
    let chair_configurations = data?.calendar_chairs ?? []

    if (!chairs.length) return []
    return chairs.map(({ _id }) => {
      let config = chair_configurations.find(item => item.chair._id === _id)
      if (config) {
        return {
          id: config.chair._id,
          vaxiom_id: config.chair.id,
          name: config.chair.full_name,
          position: config.chair.pos,
          provider: config.provider ? employeeName(config.provider) : '-',
          assistant: config.assistant ? employeeName(config.assistant) : '-',
          office_hours: config.office_hours,
          has_appointment_slots: Boolean(config.appointment_slots?.length),
          selected_appointment_slots:
            config.selected_appointment_slots?.map(item => ({
              start_min: item.start_min,
              appointment_template:
                item.appointment_type.appointment_templates[0],
            })) || [],
        }
      }

      return { id: _id }
    })
  }, [data])
}
