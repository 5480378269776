import { v4 as uuid } from 'uuid'
import { useDataSubmit } from 'Simple/Data'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submitGlobal = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'setLocation': {
        if (
          value.selected.location_id === args.location_id &&
          value.selected.vaxiom_location_id === args.vaxiom_location_id &&
          value.selected.time_zone_id === args.time_zone_id
        )
          return

        change(next => {
          // Change location
          next.selected.location_id = args.location_id
          next.selected.vaxiom_location_id = args.vaxiom_location_id

          // Update date according to new location timezone
          let currentUtcDate = zonedTimeToUtc(
            value.selected.date,
            value.selected.time_zone_id
          )
          next.selected.date = utcToZonedTime(currentUtcDate, args.time_zone_id)
          next.selected.time_zone_id = args.time_zone_id

          // Reset scheduling
          next.scheduling = originalValue.scheduling

          // Close appointment overlay - only for this first iteration -
          next.appointment_overlay = originalValue.appointment_overlay
        })

        // Reset global appointment overlay - for this first iteration -
        // This will have to change to handle the appointment overlay
        // properly only from tab. We should implement an approach like the
        // one used for comms tab to receive appointment_id as sync tab params
        submitGlobal({ type: 'appointmentOverlay/close' })

        break
      }
      case 'appointmentOverlay/open': {
        change(next => {
          next.appointment_overlay.appointment_id = args.appointment_id
        })
        schedulingReset()
        break
      }
      case 'appointmentOverlay/close': {
        change(next => {
          next.appointment_overlay.appointment_id = null
        })
        schedulingReset()
        break
      }
      case 'focusEvent': {
        focusEvent(args.id)
        break
      }
      case 'scheduling/selectUntemplatedSlot': {
        change(next => {
          let { start_min, end_min, chair_id, date, focus } = args
          let id = `${uuid()}-candidate-untemplated`
          next.scheduling.slot_id = null
          next.scheduling.untemplated_slot = {
            id,
            start_min,
            end_min,
            chair_id,
            date,
          }
          if (focus) focusEvent(id)
        })
        break
      }
      case 'scheduling/setTemplatedSlots': {
        schedulingReset()
        change(next => {
          next.scheduling.appointment_id = args.appointment_id
          next.scheduling.slots = args.slots
          next.scheduling.duration = args.duration
        })
        break
      }
      case 'scheduling/selectTemplatedSlot': {
        change(next => {
          next.scheduling.slot_id = args.id
          next.scheduling.untemplated_slot =
            originalValue.scheduling.untemplated_slot
          if (args.focus) focusEvent(args.id)
        })
        break
      }
      case 'scheduling/deselectAnySlot': {
        change(next => {
          next.scheduling.slot_id = null
          next.scheduling.untemplated_slot =
            originalValue.scheduling.untemplated_slot
        })
        break
      }
      case 'scheduling/reset': {
        schedulingReset()
        break
      }
      default: {
        break
      }
    }

    /**
     * @param {string} id
     * @returns {void}
     */
    function focusEvent(id) {
      if (id) {
        change(next => {
          next.focused_event_id = id
        })
      }
    }

    /**
     * @returns {void}
     */
    function schedulingReset() {
      change(next => {
        next.scheduling = originalValue.scheduling
      })
    }
  }
}
