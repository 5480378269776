import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFeeAfterInsurances,
  getDefaultPrimaryPayor,
  getNumberOfInstallmentsPerMonth,
  LOCK,
} from 'Data/payment-plan.js'

export default function useDataTransform(props, data) {
  let default_payment_plan = useGetDefaultPaymentPlan(props)

  return useMemo(
    () => (data?.length ? mapPaymentPlan(data[0]) : default_payment_plan),
    [data, default_payment_plan]
  )
}

function useGetDefaultPaymentPlan(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let default_payment_plan = {
      treatment_plan_id: treatment_plan.id,
      treatment_fee: treatment_plan.fee,
      insurance_code_id: treatment_plan.insurance_code_id,
      discounts: [],
      charges: [],
      // insurance section toggled on by default
      insurances: [
        {
          id: crypto.randomUUID(),
          insured_id: null,
          insured: null,
          is_primary: true,
          estimated_reimbursement_amount: 0,
          is_overridden: false,
        },
      ],
      payors: [],
    }

    return {
      ...default_payment_plan,
      payors: [getDefaultPrimaryPayor(default_payment_plan)],
    }
  }, [treatment_plan])
}

function mapPaymentPlan(payment_plan) {
  let treatment_fee = payment_plan.treatment_fee
  // calculate the percentage based on the given amount
  let treatment_fee_discounts = payment_plan.discounts
    .filter(discount => !discount.is_applied_to_payor)
    .map(discount => ({
      id: discount.id,
      type_id: discount.type_id,
      name: discount.type?.name,
      type: 'percentage',
      amount: -discount.transaction.amount,
      percentage:
        treatment_fee > 0
          ? Math.round((-discount.transaction.amount / treatment_fee) * 100)
          : 0,
      is_applied_to_payor: false,
      is_included_in_insurance_claim: discount.is_included_in_insurance_claim,
      payor_person_id: null,
    }))
  let treatment_fee_charges = payment_plan.charges.map(charge => ({
    id: charge.id,
    name: charge.name,
    type: 'percentage',
    type_id: charge.type_id || charge.name,
    amount: charge.transaction.amount,
    percentage:
      treatment_fee > 0
        ? Math.round((charge.transaction.amount / treatment_fee) * 100)
        : 0,
    is_included_in_insurance_claim: charge.is_included_in_insurance_claim,
  }))

  let adjusted_treatment_fee = getAdjustedTreatmentFeeAfterInsurances({
    id: payment_plan.id,
    treatment_fee,
    insurance_code_id: payment_plan.insurance_code_id,
    treatment_plan_id: payment_plan.treatment_plan_id,
    discounts: treatment_fee_discounts,
    charges: treatment_fee_charges,
    insurances: payment_plan.insurances,
  })

  let payors = payment_plan.payors.map(payor => {
    let discounts_amount = payment_plan.discounts
      .filter(
        discount =>
          discount.is_applied_to_payor &&
          discount.payor_person_id === payor.person_id
      )
      .reduce((acc, discount) => acc + discount.transaction.amount, 0)
    // discounts and payment plan are adjustments, so stored as negative in DB
    let downpayment_amount = parseFloat(
      (
        payor.share_amount +
        discounts_amount +
        payor.payment_plan.transaction.amount
      ).toFixed(2)
    )
    let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
      payor.payment_plan.installment_interval
    )
    let monthly_installment_amount =
      payor.payment_plan.installment_amount * number_of_installments_per_month
    let payment_plan_length =
      monthly_installment_amount > 0
        ? Math.ceil(
            -payor.payment_plan.transaction.amount / monthly_installment_amount
          )
        : 0

    return {
      id: payor.id,
      is_primary: payor.is_primary,
      person_id: payor.person_id,
      share: {
        type: 'percentage',
        amount: payor.share_amount,
        percentage:
          adjusted_treatment_fee > 0
            ? Math.round((payor.share_amount / adjusted_treatment_fee) * 100)
            : 0,
      },
      downpayment_amount,
      installment_amount: payor.payment_plan.installment_amount,
      installment_interval: payor.payment_plan.installment_interval,
      payment_plan_length,
      first_due_date: payor.payment_plan.first_due_date,
      first_installment_date: payor.payment_plan.first_installment_date,
      second_installment_date: payor.payment_plan.second_installment_date,
      lock: payor.payment_plan.downpayment_locked
        ? LOCK.DOWNPAYMENT_AMOUNT
        : payor.payment_plan.installment_locked
        ? LOCK.INSTALLMENT_AMOUNT
        : payor.payment_plan.plan_length_locked
        ? LOCK.PAYMENT_PLAN_LENGTH
        : null,
    }
  })

  let payor_discounts = payment_plan.discounts
    .filter(discount => discount.is_applied_to_payor)
    .map(discount => {
      let payor_share_amount = payors.find(
        payor => payor.person_id === discount.payor_person_id
      ).share.amount

      return {
        id: discount.id,
        type_id: discount.type_id,
        name: discount.type?.name,
        type: 'percentage',
        amount: -discount.transaction.amount,
        percentage:
          payor_share_amount > 0
            ? Math.round(
                (-discount.transaction.amount / payor_share_amount) * 100
              )
            : 0,
        is_applied_to_payor: true,
        // discounts applied to payor's share are never included in insurance claim
        is_included_in_insurance_claim: false,
        payor_person_id: discount.payor_person_id,
      }
    })

  return {
    id: payment_plan.id,
    treatment_fee,
    insurance_code_id: payment_plan.insurance_code_id,
    treatment_plan_id: payment_plan.treatment_plan_id,
    request_id: payment_plan.request_id,
    discounts: [...treatment_fee_discounts, ...payor_discounts],
    charges: treatment_fee_charges,
    insurances: payment_plan.insurances,
    payors,
  }
}
