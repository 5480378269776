// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/TreatmentPlans/Content/TreatmentPlan/mutation-delete-appointment.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_TreatmentPlans_Content_TreatmentPlan($id: numeric!) {
  treatments_delete_treatment_plan_appointment(args: { id: $id }) {
    id
  }
}

`