/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'sign': {
        break
      }
      case 'upload': {
        break
      }
      default: {
        break
      }
    }
  }
}
