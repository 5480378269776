/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'download': {
        break
      }
      case 'edit': {
        change(next => {
          next.disabled = false
        })
        break
      }
      case 'done':
      case 'cancel': {
        change(next => {
          next.disabled = true
        })
        break
      }
      default: {
        break
      }
    }
  }
}
