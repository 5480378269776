// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/Calendar/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_Calendar_Content(
  $chair_ids: [uuid!]!
  $date: date!
  $start_min: timestamp!
  $start_max: timestamp!
) {
  vaxiom_appointment_bookings(
    where: {
      chair: { _id: { _in: $chair_ids } }
      local_start_date: { _eq: $date }
      state: { _nin: ["PATIENT_CANCELLED", "OFFICE_CANCELLED"] }
    }
  ) {
    id
    _id
    local_start_date
    local_start_time
    duration
    state
    confirmation_status
    chair {
      id
      _id
    }
    appointment {
      id
      _id
      patient {
        id
        _id
        person {
          id
          first_name
          greeting
          last_name
          gender
          birth_date
        }
      }
      type {
        id
        display_name
        display_color_id
        appointment_templates {
          id
          duration
        }
      }
      tx {
        id
        colors
      }
    }
  }
  vaxiom_schedule_notes(
    where: {
      start_time: { _gte: $start_min, _lte: $start_max }
      chair: { _id: { _in: $chair_ids } }
    }
  ) {
    id
    _id
    start_time
    duration
    content
    alert_this_day
    is_blocking_time
    sys_created
    chair {
      _id
    }
    created_by {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`