import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import query from './query.graphql.js'
import mutationSendRequest from './mutation-send-request.graphql.js'
import mutationCancelRequest from './mutation-cancel-request.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onSendRequest = useOnSendRequest(props)
  let onCancelRequest = useOnCancelRequest(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'send-request':
        return onSendRequest({ value, originalValue, args, change })

      case 'cancel-request':
        return onCancelRequest({ value, originalValue, args, change })

      default:
        return
    }
  }
}

function useOnSendRequest(props) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutationSendRequest)
  let client = useClient()

  return async function onSubmit({ value, args, change }) {
    if (value.payors.length !== 1) return

    let response = await client
      .query(query, { person_id: value.payors[0].person_id })
      .toPromise()
    if (response.error || !response.data?.vaxiom_persons_by_pk) {
      notify(
        notifyError('An error has occurred while saving the treatment plan')
      )
      return
    }

    let mutationResponse = await executeMutation({
      current_location_id,
      treatment_plan_id: treatment_plan._id,
      patient_id,
      person_id: response.data.vaxiom_persons_by_pk._id,
      payment_plan: {
        ...value,
        insurances: getPaymentPlanInsurances(value, treatment_plan),
      },
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem creating the treatment plan request. Please try again.'
        )
      )
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'Content/Send/Content'))
  }
}

function useOnCancelRequest(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutationCancelRequest)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: args.treatment_plan_id,
      person_id: args.person_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem cancelling the treatment plan request. Please try again.'
        )
      )
      return
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
    notify(notifySuccess('Treatment plan request cancelled successfully'))
  }
}

function getPaymentPlanInsurances(payment_plan, treatment_plan) {
  return payment_plan.insurances.map(insurance => {
    let primary_insurance = payment_plan.insurances.find(
      item => item.is_primary
    )
    let { downpayment_amount, installment_amount } = insurance.is_primary
      ? getPrimaryInsuranceEstimatedReimbursement(
          insurance,
          payment_plan,
          treatment_plan
        )
      : getSecondaryInsuranceEstimatedReimbursement(
          insurance,
          primary_insurance,
          payment_plan,
          treatment_plan
        )
    return {
      insured_id: insurance.insured_id,
      estimated_reimbursement_amount: insurance.estimated_reimbursement_amount,
      is_primary: insurance.is_primary,
      // TODO: ideally these fields should be re-clalculated on the backend as all depend on the insured (subscription)
      // passing it from UI for now since it's too much work and duplicated logic in SQL
      downpayment_amount,
      installment_amount,
    }
  })
}
